import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const ScoreCardContainer = styled.section({
  maxWidth: "20rem",
  margin: "0 auto",
  "& > *": {
    width: "100%",
  },
})

const ReadMoreAnchor = styled.a({
  display: "block",
  marginBottom: "1.5rem",
})

const ServiceScorecard = () => {
  const [numClients, setNumClients] = useState(0)
  const [dataTypes, setDataTypes] = useState(0)
  const [clientRules, setClientRules] = useState(0)
  const [responsibility, setResponsibility] = useState(0)
  const [thirdParty, setThirdParty] = useState(0)
  const [downtime, setDowntime] = useState(0)
  const [score, setScore] = useState(0)

  useEffect(() => {
    let tempScore = 0
    if (numClients) {
      let clients = parseInt(numClients)
      clients = Math.max(1, clients)
      tempScore += (clients - 1) * 2
    }
    // If the service needs to know about data types
    // internal to the client services, it can cause coupling
    // or boundary problems
    if (dataTypes) {
      tempScore -= 1
    }

    if (clientRules) {
      tempScore -= 1
    }

    if (responsibility) {
      tempScore += 2
    }

    if (thirdParty) {
      tempScore -= 2
    }

    if (downtime === 1) {
      tempScore += 2
    } else if (downtime === -1) {
      tempScore -= 2
    }

    setScore(tempScore)
  }, [numClients, dataTypes, clientRules, responsibility, thirdParty, downtime])

  let scoreDescription
  if (score <= 0) {
    scoreDescription =
      "This service would probably be too tightly coupled to have any real benefit."
  } else if (score <= 3) {
    scoreDescription =
      "Making this service would have mild benefits for your application."
  } else {
    scoreDescription = "This would be good candidate for a separate service."
  }

  return (
    <Layout>
      <SEO
        title="Service Scorecard"
        description="A tool for teams to determine service eligibility ."
        keywords={[`engineering`]}
      />
      <h1 style={{ textAlign: "center" }}>The Service Scorecard</h1>
      <p>
        The Service Scorecard is a tool for teams to help them determine what
        belongs in a new service and whether it is a net benefit. See{" "}
        <a href="#description">a more robust description below.</a> A higher
        score is better for a candidate service. Scores can range from -6 to at
        least 6 if it's doing everything correctly.
      </p>
      <ScoreCardContainer>
        <label htmlFor="numClients">
          How many distinct clients exist right now?
        </label>
        <input
          id="numClients"
          onChange={e => {
            setNumClients(e.target.value)
          }}
          type="number"
          min="1"
          step="1"
        ></input>
        <ReadMoreAnchor href="#numClientsDesc">
          Read about Distinct Clients
        </ReadMoreAnchor>
        <label htmlFor="dataTypes">
          Will the service need to know about data types of client services?
        </label>
        <select
          id="dataTypes"
          value={dataTypes}
          onChange={e => {
            setDataTypes(parseInt(e.target.value))
          }}
        >
          {[
            { value: 1, label: "Yes" },
            { value: 0, label: "No" },
          ].map(opt => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </select>
        <ReadMoreAnchor href="#dataTypesDesc">
          Read about Data Types
        </ReadMoreAnchor>
        <label htmlFor="clientRules">
          Will the service need special behaviors for different clients?
        </label>
        <select
          id="clientRules"
          value={clientRules}
          onChange={e => {
            setClientRules(parseInt(e.target.value))
          }}
        >
          {[
            { value: 1, label: "Yes" },
            { value: 0, label: "No" },
          ].map(opt => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </select>
        <ReadMoreAnchor href="#clientRulesDesc">
          Read about Client Rules
        </ReadMoreAnchor>
        <label htmlFor="responsibility">
          Do you know who will be responsible for maintaining the new service?
        </label>
        <select
          id="responsibility"
          value={responsibility}
          onChange={e => {
            setResponsibility(parseInt(e.target.value))
          }}
        >
          {[
            { value: 1, label: "Yes" },
            { value: 0, label: "No" },
          ].map(opt => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </select>
        <ReadMoreAnchor href="#responsibilityDesc">
          Read about Responsibility
        </ReadMoreAnchor>
        <label htmlFor="thirdParty">
          Can a third party provide the service?
        </label>
        <select
          id="thirdParty"
          value={thirdParty}
          onChange={e => {
            setThirdParty(parseInt(e.target.value))
          }}
        >
          {[
            { value: 1, label: "Yes" },
            { value: 0, label: "No" },
          ].map(opt => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </select>
        <ReadMoreAnchor href="#thirdPartyDesc">
          Read about Third Party Offerings
        </ReadMoreAnchor>
        <label htmlFor="downtime">
          If the service is unavailable, can your application still provide
          value?
        </label>
        <select
          id="downtime"
          value={downtime}
          onChange={e => {
            setDowntime(parseInt(e.target.value))
          }}
        >
          {[
            { value: 0, label: "--" },
            { value: 1, label: "Yes" },
            { value: -1, label: "No" },
          ].map(opt => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </select>
        <ReadMoreAnchor href="#downtimeDesc">
          Read about Distinct SLAs
        </ReadMoreAnchor>
        <h2 style={{ width: "100%", textAlign: "right" }}>
          Service Total {score}
        </h2>
        <p>{scoreDescription}</p>
      </ScoreCardContainer>
      <section>
        <h2 id="description">Background</h2>
        <p>
          The advantages of splitting out services are evangelized constantly to
          developers and managers. And why not? Who doesn't love separation of
          concerns, faster iteration, as well as independent scaling and SLAs.
          However, designing the boundaries of those services is a hard problem
          and it can go poorly. How poorly? You can easily end up with something
          much worse than an unruly monolith.
        </p>
        <p>
          When your functions share space in memory, it's much easier to have
          tightly coupled interfaces. For example, you may have a rich context
          object that gets passed down through a series of methods. Small
          changes to that context object require changes to a whole swath of
          unrelated functions down the chain. This kind of thing slows down
          development.
        </p>
        <p>
          Creating a service and placing certain functions in it encourages you
          to think about the interface and the boundaries of responsibility. Yet
          it makes no guarantees. It's bad enough if you are passing around rich
          context objects between entirely separate functions. When you do this
          kind of thing with services, you are forcing situations like
          coordinated deployments. You now have slower iteration than with a
          monolith.
        </p>
        <p>
          This scorecard contains a series of reflective questions designed to
          get teams to think about what a new service will consist of and
          whether or not it's a net positive for the application.
        </p>
      </section>
      <section>
        <h2 id="numClientsDesc">Number of Clients</h2>
        <h3>Scoring</h3>
        <p>For every client > 1, +2 points.</p>
        <h3>Rationale</h3>
        <p>
          Every service adds overhead to a project. The biggest impact in
          offsetting that overhead depends on the number of distinct clients
          that can make use of it. If you only have one client, you really just
          have one service that happens to be separated by a network interface.
          Cleanly separated function calls and good error handling will provide
          almost all the same benefits without the context switching, secrets
          management, etc. that goes with splitting out a new service.
        </p>
        <p>
          On the other hand, for every client service after the first, you're
          getting a greater return on investment. Each client represents
          functionality that doesn't have to be copied, consistency across your
          application, and you have clear ownership over a problem domain.
        </p>
        <h2 id="dataTypesDesc">Data Types</h2>
        <h3>Scoring</h3>
        <p>If yes, -1</p>
        <h3>Rationale</h3>
        <p>
          Intimate knowledge about clients' data types indicates there will be
          tightly coupled business logic between the services. When services are
          tightly coupled, it means that changes are more likely to require
          coordination. It also implies that your service has a leaky
          abstraction where the boundary between what one service is responsible
          for versus another is fluid.
        </p>
        <h2 id="clientRulesDesc">Unique Client Behaviors</h2>
        <h3>Scoring</h3>
        <p>If yes, -1</p>
        <h3>Rationale</h3>
        <p>
          Client specific behaviors are another example of a service
          internalizing the business logic of other application components. It's
          likely that down the road this will require coordinated deployments
          when the behavior needs to change. Additionally, this approach doesn't
          scale. You can avoid this if the behavior can be parameterized as an
          option to a function call.
        </p>
        <h2 id="responsibilityDesc">Responsibility &amp; Ownership</h2>
        <h3>Scoring</h3>
        <p>If Yes, +2</p>
        <h3>Rationale</h3>
        <p>
          Assigning ownership to a service will result in a better service over
          time. When a team is responsible for a service, it is more likely to
          be kept up to date. It is less likely to have security flaws. When
          triage is necessary, it should take less time to solve.{" "}
        </p>
        <h2 id="thirdPartyDesc">Third Party Offerings</h2>
        <h3>Scoring</h3>
        <p>If yes, -2</p>
        <h3>Rationale</h3>
        <p>
          The best candidates for separate services are commmonly offered as
          SaaS platforms. This is because they are agnostic to most business
          logic. Building your own service when a third party is a viable option
          will likely result in a worse result that costs more engineering time
          and money. Some times third party services are not an option due to
          privacy, security, or compliance concerns. In this case, consider your
          response a "No".
        </p>
        <h2 id="downtimeDesc">Downtime &amp; Distinct SLAs</h2>
        <h3>Scoring</h3>
        <p>If yes, +2. If no, -2</p>
        <h3>Rationale</h3>
        <p>
          One of the largest benefits of network separated services that each
          service warrants a different SLA. If your entire application stops
          functioning when this service goes down, you simply have a network
          separated monolith. Authentication services ride a fine line here.
          They typically require the highest SLAs and if users cannot log in
          then most other features are often unavailable. Often shared
          authentication is a necessity, so use your judgement.
        </p>
        <p>
          On the other hand, a service that has minimal impact on uptime for the
          application as a whole is a great candidate. It can be thought of as a
          pluggable asset that can be manipulated as necessary.
        </p>
      </section>
    </Layout>
  )
}

export default ServiceScorecard
